<template>
<div>
  <div>
    <h1 class="mr-4">Manager</h1>
    <div>
      <debug-obj v-if="$debug.isDeveloperOn" :objData="manager"/>
      promotedEventId: {{ manager.promotedEventId }}
    </div>
  </div>
  <div class="d-flex">
    <h1 style="display: inline;" class="mr-4">Events</h1>
    <div class="align-self-center">
      <select>
        <option value="upcoming">Upcoming</option>
        <option value="passed">Passed</option>
      </select>
    </div>
  </div>
  <div v-if="events">
    <div class="flex-fill mb-1" v-for="e in events" v-bind:key="e.id">
      {{ e.id }}&nbsp;
      <button class="btn badge btn-secondary mr-1" @click="activate(e.id, true)" v-if="!manager.activeEvents || !manager.activeEvents.includes(e.id)">Activate</button>
      <button class="btn badge btn-secondary mr-1" @click="activate(e.id, false)" v-else>Disactivate</button>
      <button class="btn badge btn-outline-danger mr-1" @click="$bvModal.msgBoxConfirm('Are you sure you want to reset this event?', {centered: true}) && reset(e.id)">Reset</button>
      <button class="btn badge btn-outline-danger mr-1" v-if="$debug.isDeveloperOn && e.template" @click="resetEventFromTemplate(e)">Reset From Template</button>
      <button class="btn badge btn-danger mr-1" v-if="$debug.isSuperAdminOn" @click="deleteEvent(e)">Delete</button>
    </div>
  </div>
  <div>
    <h1 style="display: inline;" class="mr-4">New Event</h1>
    <div class="container">
      <div class="row">
        <label class="col-4" for="id">Unique ID</label>
        <b-form-input class="col" id="id" type="text" v-model="id"/>
      </div>
      <div class="row">
        <label class="col-4" for="type">Event template</label>
        <b-form-select class="col" id="type" v-model="template" :options="templateList"/>
      </div>
      <div class="row">
        <label class="col-4" for="location">Location</label>
        <b-form-input class="col" id="location" type="text" v-model="location"/>
      </div>
      <div class="row">
        <label class="col-4" for="dp">Date</label>
        <b-form-datepicker class="col" id="dp" v-model="date" reset-button></b-form-datepicker>
      </div>
      <div class="row" v-if="date">
        <label class="col-4" for="stp">Start time</label>
        <b-form-timepicker class="col" id="stp" v-model="startTime"></b-form-timepicker>
      </div>
      <div class="row" v-if="date">
        <label class="col-4" for="etp">End time</label>
        <b-form-timepicker class="col" id="etp" v-model="endTime"></b-form-timepicker>
      </div>
      <div class="row">
        <label class="col-4" for="etp">Open Registration</label>
        <b-checkbox class="col" v-model="openRegistration"></b-checkbox>
      </div>
      <div class="row">
        <button class="ml-2 btn btn-primary" @click="createEvent()">Create</button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { db, timestampFromDate } from '@/services/db';
import { createEventFromTemplate, resetEventFromTemplate } from "@/services/events";
import { getLog } from "@/services/log";
import { deleteCollection } from '@/services/dbutils';
let log = getLog('admin-events');

export default {
  props: {
    manager: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      events: [],

      // Editor
      id: "test-",
      template: "default_dating",
      type: "matchmaking",
      location: "Los Angeles",
      date: null,
      startTime: "19:00:00",
      endTime: "19:30:00",
      openRegistration: false,

      templateList: [
        { value: "default_dating", text:"Speed Dating (Match Making)" },
        { value: "default_pro", text:"Networking Mixer (Match Making)" },
        { value: "default_call", text:"Call (Queue)" },
        { value: "default_meeting", text:"Meeting Room"},
        { value: "default_stage", text:"Stage (Live Show)"},
      ],
    }
  },
  mounted() {
    this.$bind("events", db.collection("LiveEvents").where("display", "==", true));
  },
  methods: {
    createEvent() {
      let params = {
        template: this.template,
        location: this.location,
        listed: true,
        openRegistration: this.openRegistration,
      };
      if (this.date) {
        params.startDate = timestampFromDate(new Date(Date.parse(this.date + "T" + this.startTime)));
        params.endDate = timestampFromDate(new Date(Date.parse(this.date + "T" + this.endTime)));
        if (params.startDate > params.endDate)
          throw "Start Date must be before End Date";
      }
      log.log("event params=", params);
      createEventFromTemplate(this.id, this.template, params);
    },
    activate(eventId, add) {
      let activeEvents = this.manager.activeEvents || [];
      if (add) {
        if (!activeEvents.includes(eventId)) {
          activeEvents.push(eventId);
          db.collection("LiveEvents").doc("manager").update({activeEvents});
        }
      } else {
        if (activeEvents.includes(eventId)) {
          activeEvents = activeEvents.filter(eid => eid != eventId);
          db.collection("LiveEvents").doc("manager").update({activeEvents});
        }
      }
    },
    reset(eventId) {
      log.log(`reset event ${eventId}`);
      db.collection(`LiveEvents`).doc(eventId).update({
        state:null,
        doorsClosed:false,
        lastUpdate:null,
        eventComplete: null,
        lastUpdateEnd:null,
      });
    },
    resetEventFromTemplate,
    deleteEvent(e) {
      if (this.manager?.activeEvents.includes(e.id))
        this.activate(e.id, false);
      db.collection(`LiveEvents`).doc(e.id).delete();
      deleteCollection(db.collection(`LiveEvents/${e.id}/recordings`));
      deleteCollection(db.collection(`LiveEvents/${e.id}/users`));
    }
  }
}
</script>

<style>

</style>